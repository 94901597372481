export default {
    init() {
        const banner = document.querySelector(
            '.countdown-banner'
        ) as HTMLElement;
        const closeButton = document.querySelector('#close') as HTMLElement;
        const header = document.querySelector('header');

        if (!closeButton || !header || !banner) return;

        closeButton.addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                // hide banner
                const content = document.querySelector(
                    '.content'
                ) as HTMLElement;
                banner.classList.add('remove-banner');
                header.classList.add('remove-banner');

                header.style.transform = 'translateY(0)';
                content.style.transform = 'translateY(0)';

                if (content) {
                    content.classList.add('remove-banner');
                }

                // set cookie, expires date -> one week
                const d = new Date();
                d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
                const expires = 'expires=' + d.toUTCString();

                document.cookie = 'banner_removed=1;' + expires + '; path=/';
            },
            false
        );

        if (document.cookie.indexOf('banner_removed=1') !== -1) {
            // hide banner
            banner.classList.add('hide-banner');
            header.classList.add('transform');
            header.classList.add('translate-y-0');
        } else {
            // show banner
            const content = document.querySelector('.content') as HTMLElement;
            banner.classList.add('show-banner');

            const bannerHeight = banner.clientHeight;

            header.style.transform = 'translateY(' + bannerHeight + 'px)';

            if (content) {
                content.style.transform = 'translateY(' + bannerHeight + 'px)';
            }
        }

        const dateContainer = banner.querySelector(
            '.date-container'
        ) as HTMLElement;

        if (!dateContainer) return;

        const dueDate = dateContainer.dataset.date;
        const daysDiv = dateContainer.querySelector(
            '.days .number'
        ) as HTMLElement;
        const hoursDiv = dateContainer.querySelector(
            '.hours .number'
        ) as HTMLElement;
        const minutesDiv = dateContainer.querySelector(
            '.minutes .number'
        ) as HTMLElement;
        const secondsDiv = dateContainer.querySelector(
            '.seconds .number'
        ) as HTMLElement;

        if (!dueDate || !daysDiv || !hoursDiv || !minutesDiv || !secondsDiv)
            return;

        const dueDateTime = new Date(dueDate).getTime();

        if (!dueDateTime) return;

        udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);

        // call function each second
        setInterval(function () {
            udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);
        }, 1000);

        function udpateCountdown(
            daysDiv: HTMLElement,
            hoursDiv: HTMLElement,
            minutesDiv: HTMLElement,
            secondsDiv: HTMLElement
        ) {
            const now = new Date().getTime();
            const timeleft = dueDateTime - now;
            const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            daysDiv.innerHTML = days + '';
            hoursDiv.innerHTML = hours + '';
            minutesDiv.innerHTML = minutes < 10 ? '0' + minutes : minutes + '';
            secondsDiv.innerHTML = seconds < 10 ? '0' + seconds : seconds + '';
        }
    }
};
